import { Injectable } from '@angular/core';
import { INavData } from '@coreui/angular';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  pageRole: any;
  roleId: number;
  saveBtnHideForRole = true;

  constructor() { }

  public setMenu(): INavData[] {

    const rolestr = (localStorage.getItem('roleInfo'));
    const role = JSON.parse(rolestr);

    const MENU_ITEMS: INavData[] = [];
    const Dashboard: INavData = {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer',
        };

    const Master: INavData = {
      name: 'Masters',
      icon: 'icon-list',
      children: []
    };

    const User: INavData = {
      name: 'User',
      icon: 'icon-user',
      children: []
    };

    const Application: INavData = {
      name: 'Applications',
      icon: 'icon-puzzle',
      children: []
    };
    const Placement: INavData = {
        name: 'Placement',
        icon: 'layout-outline',
        children: []
      };

      const Reports: INavData = {
        name: 'Reports',
        icon: 'icon-docs',
        // url: '/master/report'
        children: []
      };

    const Role: INavData = {
      name: 'Role',
      icon: ' icon-badge',
      children: []
    };

    MENU_ITEMS.push(Dashboard); // 0
    MENU_ITEMS.push(Master); // 1
    MENU_ITEMS.push(User); // 2
    MENU_ITEMS.push(Application); // 3
    MENU_ITEMS.push(Placement); // 4
    MENU_ITEMS.push(Reports); // 5
    MENU_ITEMS.push(Role); // 6
    // MENU_ITEMS.push(ReleasePayment);  // 7
    // MENU_ITEMS.push(Blogs); // 8
    // MENU_ITEMS.push(Donations); // 9

    if (role.page) {
      role.page.forEach(item => {
        // Master
        if (item.page === 'Course Master' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'Course ',
                url: '/master/course',
                icon: 'icon-notebook',
            //   icon: 'icon-bulb'
            }
          );
        }
        if (item.page === 'Assessment Master' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'Assessment',
                url: '/master/assessment',
                icon: 'icon-question',
            }
          );
        }
        if (item.page === 'Question Set' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'Question Set',
                url: '/master/queationset',
                icon: 'icon-fire',
            }
          );
        }
        if (item.page === 'Company Master' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'Company',
                url: '/master/company',
                icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Video' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'Video',
                url: '/master/videos',
                icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Video Sequence' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'VideoCourse',
                url: '/master/videocourse',
                icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Help and Support' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'Help and Support',
                url: '/master/helpsupport',
                icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Banner' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'Banner',
                url: '/master/banner',
                icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Blog' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[1].children.push(
            {
                name: 'Blog',
                url: '/master/blog',
                icon: 'icon-cursor'
            }
          );
        }
        // User ------------------------------------------------------------------
        if (item.page === 'Register User' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[2].children.push(
            {
                name: 'Register-user',
                url: '/master/register-user',
                icon: 'icon-people',
            }
          );
        }
        if (item.page === 'Employee' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[2].children.push(
            {
              name: 'Employee',
              url: '/master/employee',
              icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Subscribed User' && (item.isRead || item.isWrite)) {
            MENU_ITEMS[2].children.push(
              {
                  name:'Subscribed-Student',
                  url: '/master/subscribed-user',
                  icon: 'icon-people',
              }
            );
          }
        // Applications  ----------------------------------------
        if (item.page === 'Applied Job' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[3].children.push(
            {
                name: 'Job',
                url: '/master/job-apply',
                icon: 'icon-cursor'
            }
          );
        }
        if (item.page === 'Applied Internship' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[3].children.push({
            name: 'Internship',
            url: '/master/internship-apply',
            icon: 'icon-cursor'
          });
        }
        // Placement -------------------------------------------------------------------
        if (item.page === 'Job' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[4].children.push(
            {
                name: 'Job',
                url: '/master/placement',
                icon: 'icon-cursor',
            }
          );
        }
        if (item.page === 'Internship' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[4].children.push(
            {
                name: 'Internship',
                url: '/master/internship',
                icon: 'icon-cursor',
            }
          );
        }
        // Report    ----------------------------------------------------------------
        if (item.page === 'User Report' && (item.isRead || item.isWrite)) {
          MENU_ITEMS[5].children.push(
            {
                name: ' Report',
                url: '/master/report',
                icon: 'icon-envelope-letter',
            }
          );
        }
        // Authorization -------------------------------------------------------
            if (item.page === 'Rights' && (item.isRead || item.isWrite)) {
              MENU_ITEMS[6].children.push(
                {
                    name: 'Rights',
                    url: '/master/rights',
                    icon: 'icon-cursor'
                }
              );
            }
      });

      // remove unnesessary items To DO


      //MENU_ITEMS.push(Dashboard); // 0



      // MENU_ITEMS.push(OtherList); // 6
      // const otherListResult = role.page.find(repo =>
      //   (repo.page === 'Recipe' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Rating' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Review' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Help & support' && (repo.isRead || repo.isWrite))
      // );

      // if (!otherListResult) {
      //   MENU_ITEMS.splice(6, 1);
      // }

      // MENU_ITEMS.push(Reports); // 7

      // const releasePaymentResult = role.page.find(repo =>
      //   (repo.page === 'Business partner' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Delivery boy' && (repo.isRead || repo.isWrite))
      // );

      // if (!releasePaymentResult) {
      //   MENU_ITEMS.splice(5, 1);
      // }


      // check for reports   5

      const reportResult = role.page.find(repo =>

        (repo.page === 'User Report' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Subscribed User Report' && (repo.isRead || repo.isWrite))
      );

      if (!reportResult) {
        MENU_ITEMS.splice(5, 1);
      }

      //  MENU_ITEMS.push(Placement); // 4
      const orderResult = role.page.find(repo =>
        (repo.page === 'Job' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Internship' && (repo.isRead || repo.isWrite))
      );

      if (!orderResult) {
        MENU_ITEMS.splice(4, 1);
      }

      // MENU_ITEMS.push(Application); // 3

      const inventoryResult = role.page.find(repo =>
        (repo.page === 'Applied Job' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Applied Internship' && (repo.isRead || repo.isWrite))
      );

      if (!inventoryResult) {
        MENU_ITEMS.splice(3, 1);
      }

      // MENU_ITEMS.push(User); // 2


      const userResult = role.page.find(repo =>
        (repo.page === 'Register User' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Employee' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Subscribed User' && (repo.isRead || repo.isWrite))
      );

      if (!userResult) {
        MENU_ITEMS.splice(2, 1);
      }

      // MENU_ITEMS.push(Master); // 1
      const masterResult = role.page.find(repo =>
        (repo.page === 'Course Master' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Assessment Master' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Question Set' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Company Master' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Video' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Video Sequence' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Help and Support' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Banner' && (repo.isRead || repo.isWrite)) ||
        (repo.page === 'Blog' && (repo.isRead || repo.isWrite))
      );

      if (!masterResult) {
        MENU_ITEMS.splice(1, 1);
      }

      // dashboard 0

      // const dashboardResult = role.page.find(repo =>
      //   (repo.page === 'New' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Accept' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'In-Process' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Delivered' && (repo.isRead || repo.isWrite)) ||
      //   (repo.page === 'Cancelled' && (repo.isRead || repo.isWrite))
      // );

      // if (!dashboardResult) {
      //   MENU_ITEMS.splice(0, 1);
      // }
    }
    return MENU_ITEMS;
  }
}
